import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-scroll'
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import bgVideo from "../../assets/image/videos/background.png";
import imgDF from "../../assets/image/home-6/png/desktop-frame.png";
import imgMF from "../../assets/image/home-6/png/mobile-frame.png";
import imgInteractiveImages from "../../assets/image/links/group.png";


const Content3 = ({ className, ...rest }) => {
  const slickSettings = {
    dots: true,
    infinite: true,
    fade:true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 15000,    
    responsive: [
      {
        breakpoint: 769,
        settings: 'unslick',
      },
    ],
  };
  return (
    <ParallaxProvider>   
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row pt-15 pb-10 pt-lg-15 pb-lg-0 align-items-center justify-content-center position-static">
          <div className="col-xl-6 col-lg-6 order-2 order-lg-1" data-aos="zoom-in-right" data-aos-once="true" data-aos-delay="750">
            <div className="light-mode-texts mt-0 mt-lg-0 pr-xs-15 pr-sm-14 pr-md-13 pr-lg-0 pr-lg-15">
              <h1
                className="font-size-10 mb-2 letter-spacing-n83  pr-xs-25 pr-sm-15 pr-md-15 pr-lg-0"
              >
                Elevate your <span>video content</span>
              </h1>
              <p
                className="font-size-5 mb-0"
              >
                Generate customized interactive videos that will delight your viewers. Our next-gen software supports  hotspots, branching, overlay capabilities and more.
              </p>
              <div className="mt-9 mt-lg-12 d-flex">
                <a href="https://app.digma.io?mode=login" className="btn text-white btn-dodger-blue-2 btn-xl h-55 rounded-5">try for free</a>                 
                <Link to="demo" className="btn btn-outline-video-1 Videos btn-xl h-55 rounded-5 ml-5" spy={true} smooth={true} duration={500} >View Demo</Link>
              </div>
            </div>
          </div>
          <div className="d-block d-md-none col-xl-7 col-lg-6 order-lg-2 order-1" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
            {/* content-2 start */}
            <div className="l5-content-gallery-img">
              {/* content img start */}
              <div className="d-xs-flex ml-lg-15 mx-xl-n3">
                <div className="d-flex flex-column w-100">
                  {/* single image */}
                  <video className="i-video mt-5 mb-5" muted autoPlay loop src="https://cdn.digma.io/videos/digma-desktop.mp4"></video>
                  {/* single image end */}
                </div>
              </div>
              {/* abs-content end */}
            </div>
            {/* content-2 end */}
          </div>          
          {/* Right Image */}
          <div className="d-none d-md-block col-xl-6 col-lg-6 order-1 order-lg-2">
            {/* content-2 start */}
            <div className="l5-content-gallery-img">
              <Parallax className="absolute" translateY={['100px', '-100px']} translateX={['-150px', '-150px']}>
                <img src={bgVideo} alt="video background" data-aos="zoom-in-down" data-aos-once="true" data-aos-delay="750" />
              </Parallax>
              <div className="testimonial-one mt-10" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
              <LazyLoad>
              <Slider
                {...slickSettings}
                css={`
                  .slick-slide {
                    margin: 0 1rem;
                  }
                  .slick-dots {
                    display: flex !important;
                  }
                `}
                className="l6-testimonial"
              >
              <div>
                    {/* Single table */}
                    <div className="col-xl-12 col-md-12 col-sm-10 col-xs-10">
                      <div
                        className="mb-25 mb-lg-0"
                      >
                        <div className="mb-9 standard">                    
                          <div className="pt-5 d-block position-relative d-flex justify-content-center">
                            <div className="desktop-bg-img-features">
                              <img src={imgDF} alt="Digma Interactive Video Desktop Background" />
                            </div>                            
                            <video className="i-video" muted autoPlay loop src="https://cdn.digma.io/videos/digma-desktop.mp4"></video>
                          </div>
                        </div>
                        </div>
                    </div>
                    {/* End Single table */}
              </div>                 
              <div>
                    {/* Single table */}
                    <div className="col-xl-12 col-md-12 col-sm-10 col-xs-10">
                      <div
                        className="mb-25 mb-lg-0"
                      >
                        <div className="mb-0 standard">                    
                          <div className="pt-5 d-block position-relative d-flex justify-content-center">
                          <div className="mobile-bg-img-features">
                              <img src={imgMF} alt="Digma Interactive Video Desktop Background" />
                            </div>                              
                            <video className="w-40 i-video mobile" muted autoPlay loop src="https://cdn.digma.io/videos/digma-mobile.mp4"></video>
                          </div>
                        </div>
                        </div>
                    </div>
                    {/* End Single table */}
              </div> 
              </Slider>
              </LazyLoad>
              </div>

            </div>
            {/* content-2 end */}
          </div>
        </div>
      </div>
    </div>
    </ParallaxProvider>

  );
};

export default Content3;
