import React from "react";
import plugins from "../../assets/image/videos/metrics.jpg"

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-10 align-items-center justify-content-center">
          <div
            className="order-lg-1 order-2 col-xl-5 col-lg-5 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0 pr-10">
              <h2 className="font-size-10 mb-2">
              Metrics
              </h2>
              <p>We have all the metrics you will need to see the difference Interactive video makes to your marketing.</p>            
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>25% better conversion rates</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>18% more leads generated, 21% more web traffic</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>14% more sales</td>
              </tr>               
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>4-5x more pageviews than static content</td>
              </tr>                                                       
            </table>                 
              <div className="mt-9">
                <a className="btn bg-video text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="order-lg-2 order-1 col-xl-7 col-lg-7 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={plugins} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
