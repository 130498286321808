import React from "react";
import branding from "../../assets/image/videos/branching.jpg";


const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-10 align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={branding} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
              Branching
              </h2>
              <p className="font-size-6 mb-5">
                A branching video is a type of interactive video where the viewer is asked to participate actively in the narrative by choosing between different actions or storylines.
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Engaging and compelling</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Improve viewer retention</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Drive conversions</td>
              </tr>               
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>They are fun!</td>
              </tr>                                                       
            </table>                 
              <div className="mt-9">
                <a className="btn bg-video text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
