import React from "react";
import plugins from "../../assets/image/videos/overlays.jpg"

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-10 align-items-center justify-content-center">
          <div
            className="order-lg-1 order-2 col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
               Overlays
              </h2>
              <p className="font-size-6 mb-0">
                Clicking on the video, the user can discover in-depth information on what you are showcasing. We have an ever-expanding list of widgets. Here are a few:
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Live data widget</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Lightbox image galleries</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Image Carousels</td>
              </tr>               
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Data-capture forms</td>
              </tr>                                                       
            </table>               
              <div className="mt-9">
                <a className="btn bg-video text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="order-lg-2 order-1 col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={plugins} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
