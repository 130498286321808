import React from "react";
import hotspot from "../../assets/image/videos/hotspots.jpg";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-lg-10 align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={hotspot} alt="hotspot example" />
              </div> 
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Hot Spots
              </h2>
              <p className="font-size-6 mb-5">
                A hotspot is the area of the interactive video that acts as a 'hitbox' – clicking on the hotspot will lead to the content or action of your choice.
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Increase engagement</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Increase conversions</td>
              </tr>                 
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Supply extra information</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-video border-0 text-white" /></td>
                <td>Full customization</td>
              </tr>                                                     
            </table>               
              <div className="mt-9">
                <a className="btn bg-video text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
