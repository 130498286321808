import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import PageWrapper from "../components/PageWrapper";
import SEO from '../components/SEO';

import WaveReverse from "../sections/common/WaveReverse";
import VideoDemo from "../sections/common/VideoDemoNew";
import HeroNew from "../sections/interactive-video/Content3";
import Pricing from "../sections/home4/Pricing";
import FeatureOne from "../sections/interactive-video/FeatureOne";
import FeatureTwo from "../sections/interactive-video/FeatureTwo";
import FeatureThree from "../sections/interactive-video/FeatureThree";
import FeatureFour from "../sections/interactive-video/FeatureFour";
import Commit from "../sections/common/Commit";

import metaImg from '../assets/image/metaimg/meta-video.jpg';
import yellowCricle from '../assets/paraimages/yellow-circle.png';
import pinkTriangle from '../assets/paraimages/pink-triangle.png';
import blueCircle from '../assets/paraimages/blue-circle.png';
import fullyChargedQr from "../assets/qr-images/fullycharged.png";
import whQr from "../assets/qr-images/wh.png";
import atQr from "../assets/qr-images/at.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    ><FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"      
      tabIndex={0}
   ><FontAwesomeIcon icon={faAngleRight} />
   </div>
  );
}

const Demo = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });  
  const slickSettings = {
    dots: false,
    infinite: true,
    fade:false,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 6000,  
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          dots: true,
          nextArrow: false,
          prevArrow: false, 
          arrows: false,         
        },
      },
    ],         
  };
  const slickSettingsQr = {
    dots: false,
    infinite: true,
    fade:false,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 6000,          
  };  
  return (    
  <ParallaxProvider>   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          footerStyle: "digma",
          version:"videos-version",
        }}>
        <SEO
          title="Interactive Video Player - Elevate your video content"
          description="Digma's unique SAAS platform allows you to easily supercharge any video by offering engaging, relevant and timely content via customisable interactive overlays."
          image={metaImg}
        />  
       <HeroNew />                
       <WaveReverse background="transparent" color="#F7F9FC" />  
        <div className="grey pt-20 position-relative" id="video">
        <Parallax className="d-none d-md-block absolute" translateY={['0px', '200px']} translateX={['-100px', '200px']}>
          <img className="w-50 w-lg-100" src={yellowCricle} alt="yellow circle" />
        </Parallax>   
        <Parallax className="d-none d-md-block absolute right" translateY={['0px', '100px']} translateX={['100px', '-200px']}>
          <img className="w-50 w-lg-100" src={pinkTriangle} alt="pink triangle" />
        </Parallax>   
        <Parallax className="d-none d-md-block absolute right bottom half" translateY={['-100px', '-200px']} translateX={['-100px', '200px']}>
          <img className="w-50 w-lg-100" src={blueCircle} alt="blue circle" />
        </Parallax>
        <div id="demo" className="l5-content-gallery-img testimonial-one">    
        <VideoDemo className="pt-10" title="Watch how Fully Charged uses our interactive video player to increase engagement" name="" projectId="C40BCA72-6583-4C80-BFE3-3E06184FAC60" />        
          {/* <Slider
                {...slickSettings}
                  asNavFor={nav2}
                  ref={slider => (setSlider1(slider))}
                  css={`
                    .slick-slide {
                      margin: 0 1rem;
                    }
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                  className="l6-testimonial"
                >                              
          <div><VideoDemo className="pt-10" title="Watch how Fully Charged uses our interactive video player to increase engagement" name="" projectId="C40BCA72-6583-4C80-BFE3-3E06184FAC60" /></div>
          <div><VideoDemo className="pt-10" title="Golf Course Flyover" name="Let viewers find out realtime location weather and tips to improve their game." projectId="65B89E52-E4A9-4691-95C6-CC13D00596B6" /></div>
          <div><VideoDemo className="pt-10" title="Alias Trate Drone Gallery" name="Want to show your work around the globe? Alias Trate did over the pandemic." projectId="E3E2DE77-696B-4DC9-B0C4-633FEB0A6DBE" /></div>
          </Slider> */}
        </div>
        <div className="d-none d-lg-block container text-center pt-20 pb-0">
        <div>
                <h3>A unique mobile experience for consumers</h3>
                <p className="w-100 w-lg-50 m-auto pb-10">Our mobile interactive videos provide viewers with a wealth of contextual information via a unique UI designed to delight viewers.</p>
                <div className='w-33 m-auto'>                
                <img className="w-100" src={fullyChargedQr} alt="qr code to Fully Charged website" />
                </div>
              </div>          
          {/* <Slider
                {...slickSettingsQr}
                asNavFor={nav1}
            ref={slider => (setSlider2(slider))}>
              <div>
                <h3>A unique mobile experience for consumers</h3>
                <p className="w-100 w-lg-50 m-auto pb-10">Our mobile interactive videos provide viewers with a wealth of contextual information via a unique UI designed to delight viewers.</p>
                <div className='w-33 m-auto'>                
                <img className="w-100" src={fullyChargedQr} alt="qr code to Fully Charged website" />
                </div>
              </div>
              <div>
                <h3>Don't get sand trapped!</h3>
                <p className="w-100 w-lg-50 m-auto pb-10">William Hill golf club newbies can now see where the bunkers lie in this interactive flyover.</p>
                <div className='w-33 m-auto'>                
                <img className="w-100" src={whQr} alt="qr code to William Hill website" />
                </div>
              </div>
              <div>
                <h3>Visit an art gallery remotely</h3>
                <p className="w-100 w-lg-50 m-auto pb-10">Artist Alias Trate created an Interactive art exhibition throughout the lockdown for the world to see.</p>
                <div className='w-33 m-auto'>                
                <img className="w-100" src={atQr} alt="qr code to fully charged website" />
                </div>
              </div>                            
            </Slider>  */}
          </div>
        </div>

       <WaveReverse background="#F7F9FC" color="#fff" />         
        <FeatureOne className="pt-0 pb-0" />
        <WaveReverse background="#fff" color="#F7F9FC" />  
        <FeatureTwo className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <FeatureThree className="pt-0" />
        <WaveReverse background="#fff" color="#F7F9FC" />  
        <FeatureFour className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <Pricing className="pt-20 pb-0" />
        <div className='d-none d-md-block text-center pb-10 pt-10'>
        <AniLink className="font-size-7" paintDrip hex="#2196f3" to="/pricing">
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
        <Commit />
        </div>
        

      </PageWrapper>
    </ParallaxProvider>
  );
};
export default Demo;
